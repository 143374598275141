import { SvgIcon } from '@mui/material';

export default function InstructionIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ width: `16px`, height: `16px`, ...props.style }}>
      <g>
        <path
          d="M8.99989 17.145C12.9266 17.145 16.1099 13.9617 16.1099 10.035C16.1099 6.10826 12.9266 2.925 8.99989 2.925C5.07315 2.925 1.88989 6.10826 1.88989 10.035C1.88989 13.9617 5.07315 17.145 8.99989 17.145Z"
          stroke="#FAFAFA"
          stroke-width="1.5"
          strokeMiterlimit="10"
          fill="transparent"
        />
        <path d="M9 2.925V0.449997" stroke="#FAFAFA" stroke-width="1.5" strokeMiterlimit="10" />
        <path d="M9 9.87V5.94" stroke="#FAFAFA" stroke-width="1.5" strokeMiterlimit="10" stroke-linecap="round" />
        <path
          d="M7.15503 0.854996H10.845"
          stroke="#FAFAFA"
          stroke-width="1.5"
          strokeMiterlimit="10"
          stroke-linecap="round"
        />
        <path d="M9.00012 11.415C9.76227 11.415 10.3801 10.7972 10.3801 10.035C10.3801 9.27285 9.76227 8.655 9.00012 8.655C8.23796 8.655 7.62012 9.27285 7.62012 10.035C7.62012 10.7972 8.23796 11.415 9.00012 11.415Z" />
      </g>
    </SvgIcon>
  );
}
