import './styles.css';

import { validLanguages } from '@nx-smartmonkey/shared/domain';
import * as dateFnsLocales from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

(() => {
  for (const language in validLanguages) {
    let dateFnsLocalePackageName = language as string;
    if (language === `en`) {
      dateFnsLocalePackageName = `enUS`;
    }
    const locale = (dateFnsLocales as any)[dateFnsLocalePackageName];

    let dateFnsLocale = locale;

    switch (language) {
      case `ca`:
        dateFnsLocale = `es-CA`;
        break;
      case `en`:
        dateFnsLocale = `en-US`;
        break;
    }

    registerLocale(dateFnsLocale, locale);
  }
})();
