import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface CommunicationProps {
  color?: string;
  style?: React.CSSProperties;
}

export const Communication = ({ color = RoutalPalette.primary40, style = {}, ...props }: CommunicationProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 20" style={{ width: `18px`, height: `20px`, ...style }}>
      <g>
        <path
          d="M3.19418 12.4451C2.35554 11.0314 2.06184 9.36012 2.36823 7.74515C2.67462 6.13018 3.56003 4.68261 4.8582 3.67425C6.15636 2.6659 7.77798 2.16613 9.41855 2.2688C11.0591 2.37146 12.6058 3.06949 13.7681 4.23181C14.9304 5.39414 15.6285 6.9408 15.7312 8.58137C15.8338 10.2219 15.3341 11.8436 14.3257 13.1417C13.3174 14.4399 11.8698 15.3253 10.2548 15.6317C8.63986 15.9381 6.96859 15.6444 5.55483 14.8058L5.55485 14.8057L3.22353 15.4718C3.12707 15.4994 3.02501 15.5006 2.9279 15.4755C2.83079 15.4503 2.74218 15.3996 2.67125 15.3287C2.60031 15.2578 2.54964 15.1692 2.52448 15.0721C2.49932 14.9749 2.50058 14.8729 2.52814 14.7764L3.19418 12.4451Z"
          fill={color}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.44727 6.25732H11.5527"
          stroke={RoutalPalette.neutral00}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.44727 9.11328H11.5527"
          stroke={RoutalPalette.neutral00}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.44727 11.7483H11.5527"
          stroke={RoutalPalette.neutral00}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
