import { Language, LanguageType } from '@nx-smartmonkey/shared/domain';
import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useMutation } from '@tanstack/react-query';
import { IntlShape } from 'react-intl';
import { translationsService } from '../../application/TranslationsService';
import { TranslationsActionType } from '../../context/translations/actions';
import { useTranslationsContext } from '../../context/translations/context';

interface UseRetrieveTranslationProps {
  language: Language;
}

type UseRetrieveTranslationResponse = ServiceResponse<
  ({ language }: UseRetrieveTranslationProps) => void,
  Record<string, IntlShape>
> & {
  activeLanguage?: IntlShape;
};

// Hooks act as 'Adapter' layer.
export const useRetrieveTranslation = (): UseRetrieveTranslationResponse => {
  const { translationsState, translationsDispatch } = useTranslationsContext();
  const { isLoading, mutate, isError, isSuccess } = useMutation(
    ({
      language,
    }: UseRetrieveTranslationProps): Promise<{ language: LanguageType; translations: Record<string, string> }> => {
      return translationsService.getTranslation({ language: language.value });
    },
    {
      onError: (err: any) => {
        console.info(`Customers Retrieve Translations Error:`, err);
      },
      onSuccess: ({ language, translations }) => {
        const lang = Language.create(language);
        translationsDispatch({ type: TranslationsActionType.SET_TRANSLATION, payload: { language, translations } });
        translationsDispatch({ type: TranslationsActionType.SET_ACTIVE_LANGUAGE, payload: { language: lang } });
      },
    }
  );

  return {
    error: isError,
    isLoading,
    success: isSuccess,
    run: ({ language }: UseRetrieveTranslationProps) => mutate({ language }),
    activeLanguage: translationsState.activeLanguage,
    data: translationsState.translations,
  };
};
