import { Language } from '@nx-smartmonkey/shared/domain';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

// This should have a intlShape with fake translations for not making the application crash.
export let intl: IntlShape = createIntl(
  {
    locale: Language.getDefaultLanguage().value,
    messages: {},
  },
  cache
);

export const setSelectedIntl = (localeTranslations: IntlShape) => {
  intl = localeTranslations;
};
